import React, { Fragment, useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  ProjetsWrapper,
  ProjetLink,
  Banner,
  Spacer,
  Text2Col,
  FilterItem,
  FilterList,
  ArrowUpLink,
  ArrowUpIcon,
  ArrowDownLink,
  ArrowDownIcon,
  NavigationBottom,
} from '../components/Elements';

const projetQuery = graphql`
  {
    page: datoCmsPageProjet {
      titre
      contenu
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    banner: file(relativePath: { eq: "bandeau_projets.png" }) {
      childImageSharp {
         fluid( maxWidth:1920) {
            ...GatsbyImageSharpFluid
          }
      }
    }

    allCat: allDatoCmsTypeDeProjet(
      filter: { locale: { eq: "fr-FR" } }
      sort: { fields: [meta___createdAt], order: ASC })
      {
        edges {
          node {
            typeProjet
            id
          }
        }
      }

    projets: allDatoCmsProjet(
      filter: { locale: { eq: "fr-FR" } }
      sort: { fields: [meta___createdAt], order: ASC }
    ) {
      edges {
        node {
          id
          titre
          typeProjet  {
            typeProjet
          }
          annE
          surface
          slug
          imagePrincipale {
            fluid(maxWidth: 800, forceBlurhash: false, imgixParams: { fit: "crop", w: "551", h: "373" , fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;

export default function ProjetsPage() {
  const data = useStaticQuery(projetQuery);
  const { titre, contenu, seoMetaTags } = data.page;
  const { edges } = data.projets; //tous les projets
  const categories = data.allCat.edges; //toutes les catégories de projets

  //filtering logic
  const [products, setProducts] = useState(edges) // tous les projets (requete graphql)
  const [category, setCategory] = useState("all")
   //e = event for the value when select changes
    const handleFilterChange = (e, filterType) => {
        //changes state 
        switch (filterType) {
            
             case "category":
             /*  console.log(e.target.dataset.cat)*/
                setCategory(e.target.dataset.cat)
                break;

            default: break;
        }
    }
  //initial render then updates when state/criteria is changed
  useEffect(() => {
      let filteredProducts = edges;
      if (category !== "all") {
        
          filteredProducts = filteredProducts.filter(projet => projet.node.typeProjet.typeProjet == category)
      }
      setProducts(filteredProducts)
      //variable being listened for change
  }, [category])

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />

      <PageWrapper>
        <Banner  height="75px">
          <Img fluid={data.banner.childImageSharp.fluid} />
        </Banner>
        <PageInner>
          <PageTitle>{titre}</PageTitle>
        
          <span>Filtrer :</span>
          <FilterList>
            <FilterItem  selected = {"all" == category }  key="all" data-cat="all"  onClick={(e) => handleFilterChange(e, "category")}>TOUS</FilterItem>
            {_map(categories, (cat) => (
              <FilterItem 
                key={cat.node.id} 
                data-cat={cat.node.typeProjet}  
                onClick={(e) => handleFilterChange(e, "category")}
                 selected = {cat.node.typeProjet == category } 
              >
                {cat.node.typeProjet}
              </FilterItem>
              ))
            }
          </FilterList>

          <ProjetsWrapper>
          {_map(products, (projet) => (
            <ProjetLink key={projet.node.id}>
         
               <Link to={`/projets/${projet.node.slug}/`}>
                <Img fluid={projet.node.imagePrincipale.fluid} alt={projet.node.titre}/>
                  <h3 className="title">{projet.node.titre}</h3>
                  <span className="description">
                  {projet.node.typeProjet.typeProjet} / {projet.node.surface} m² / {projet.node.annE} </span>
              </Link>
            </ProjetLink>
          ))}
          </ProjetsWrapper>
          
          <Spacer/>
          
          <Text2Col dangerouslySetInnerHTML={{ __html: contenu }} />

          {/*<NavigationBottom>
           <ArrowUpLink to="/bla-bla" title="Bla-bla">
              Bla-bla
              <ArrowUpIcon title="Aller à la page Bla-bla" />
            </ArrowUpLink>
            <ArrowDownLink to="/ressources" title="Ressources">
              <ArrowDownIcon title="Aller à la page Ressources" />
              Ressources
            </ArrowDownLink>
          </NavigationBottom>*/}

        </PageInner>
        <Spacer/>
      </PageWrapper>
    </Fragment>
  );
}
